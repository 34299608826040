<template>
  <div class="pages">
    <div class="task_box">
      <div class="dialog">
        <div class="zuBg">
          <div class="btnBg">
            <div class="title">预警设置</div>
            <div class="inputList">
              <!-- <div class="icon"><img src="../../assets/images/dialoglogo2.png" alt=""></div> -->
              <div class="iptTitle">接头温度超限</div>
              <div class="inp">
                <!-- <input type="text" v-model="addInfo.temperature_exceed" placeholder="温度超限报警"> -->
                <el-select
                  v-model="addInfo.temperature_exceed"
                  placeholder="接头温度超限"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="inputList">
              <div class="iptTitle">环境温度超限</div>
              <div class="inp" style="flex: 1">
                <el-select
                  v-model="addInfo.temperature_hj_exceed"
                  placeholder="环境温度超限"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="inputList">
              <div class="iptTitle">接头与环境温差</div>
              <div class="inp" style="flex: 1">
                <el-select
                  v-model="addInfo.temperature_diff"
                  placeholder="接头与环境温差"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <!-- <div class="inputList">
              <div class="iptTitle">接头急剧升温</div>
              <div class="inp" style="flex: 1">
                
                <el-select
                  v-model="addInfo.temperature_mutation"
                  placeholder="接头急剧升温"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div> -->
            <!-- <div class="inputList">
              <div class="iptTitle">环境急剧升温</div>
              <div class="inp" style="flex: 1">
                <el-select
                  v-model="addInfo.temperature_hj_mutation"
                  placeholder="环境急剧升温"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div> -->
            <div class="inputList">
              <div class="iptTitle">水浸</div>
              <div class="inp" style="flex: 1">
                <el-select v-model="addInfo.water_immersion" placeholder="水浸">
                  <el-option
                    v-for="item in options3"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <!-- <div class="inputList">
             
              <div class="iptTitle">局放数值阈值</div>
              <div class="inp" style="flex: 1">
                <input
                  disabled
                  style="width: 100%"
                  type="text"
                  v-model="addInfo.partial_discharge_thresh"
                  placeholder="局放数值阈值"
                />
              </div>
            </div>
            <div class="inputList">
            

              <div class="iptTitle">局放连续监测次数</div>
              <div class="inp" style="flex: 1">
                <input
                  disabled
                  style="width: 100%"
                  type="text"
                  v-model="addInfo.partial_discharge"
                  placeholder="局放连续监测次数"
                />
              </div>
            </div> -->
            <!-- <div class="inputList">
        
          <div class="iptTitle">温度突变报警</div>
          <div class="iptTitle" @click="set">设置</div>

        </div> -->
            <div class="tijiao" @click="submit">提交</div>
          </div>
        </div>
        <!-- 添加设备弹出层 -->
        <el-dialog
          title="温度突变预警设置"
          :visible.sync="dialogFormVisible"
          width="500px"
        >
          <el-form :model="addInfo">
            <el-form-item label="设置秒数：" label-width="120px">
              <el-input v-model="addInfo.second" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="设置温度: " label-width="120px">
              <el-input
                v-model="addInfo.temperature"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addFacility()">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          value: "55",
          label: "55",
        },
        {
          value: "60",
          label: "60",
        },
        {
          value: "65",
          label: "65",
        },
        {
          value: "70",
          label: "70",
        },
        {
          value: "75",
          label: "75",
        },
        {
          value: "80",
          label: "80",
        },
      ],
      options1: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
      ],
      options2: [
        {
          value: "50",
          label: "50",
        },
        {
          value: "60",
          label: "60",
        },
        {
          value: "70",
          label: "70",
        },
        {
          value: "80",
          label: "80",
        },
      ],
      options3: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      siteList: [],
      customProps: {
        checkStrictly: true,
        multiple: true,
        children: "children",
        value: "id",
        label: "title",
      },
      zuIdname: "",
      organize_id: "",
      name: "",
      password: "",
      role: "",
      group_id: "", //角色id
      roleList: [],
      dialogFormVisible: false,
      addInfo: {
        // setSec: '',
        // setWen: '',
        temperature_diff: "",
        temperature_exceed: "",
        temperature_mutation: "",
        temperature_hj_mutation: "",
        temperature_hj_exceed: "",
        partial_discharge_thresh: "",
        partial_discharge: "",
        water_immersion: "",
      },
    };
  },
  mounted() {
    // 获取表格列表数据
    this.getTypeList();
    // this.getzuzhiList();
  },
  methods: {
    set() {
      this.dialogFormVisible = true;
    },
    addFacility() {
      this.dialogFormVisible = false;
    },
    async submit() {
      const res = await this.$http.post("Abnormal/handle", this.addInfo);
      if (res.code !== 1) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.addInfo = {
        temperature_diff: "",
        temperature_exceed: "",
        temperature_mutation: "",
        temperature_hj_mutation: "",
        temperature_hj_exceed: "",
        partial_discharge_thresh: "",
        partial_discharge: "",
        water_immersion: "",
      };
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 角色获取
    async getTypeList() {
      const res = await this.$http.post("user/user_group");
      this.roleList = res.data.list;
    },
    changeRole(e) {
      console.log(e, "ee");
      this.group_id = e;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dialog .el-input__inner {
  border: none !important;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.task_box {
  width: calc(100% - 220px);
}
.dialog {
  // position: relative;
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    // border: 1px solid #dddddd !important;
  }
  ::v-deep .el-dialog__header {
    background: #6a9eff;
  }
  ::v-deep .el-dialog__title {
    color: #fff;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
  .zuBg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    // height: 643px;
  }
  .btnBg {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1111px;
    height: 856px;
    background: url("../../assets/images/editDialog.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .btnBg .title {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4486ff;
    margin-top: 57px;
    margin-bottom: 57px;
  }
  .inputList {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border: 2px solid #4486ff;
    border-radius: 4px;
    margin-top: 13px;
    width: 700px;
    height: 50px;
    margin-bottom: 20px;
    .icon {
      width: 26px;
      height: 24px;
    }
    padding: 0 40px;
    box-sizing: border-box;
  }
  .iptTitle {
    width: 170px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 30px;
  }
  input {
    height: 52px;
    font-size: 20px;
    outline: none;
    border: none;
  }
  .inp input {
    background: rgba(0, 0, 0, 0);
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
  }
  .inputList .el-select > .el-input {
    border: none !important;
    height: 56px;
    background: rgba(0, 0, 0, 0);
  }
  .el-select {
    width: 300px !important;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .el-cascader {
    cursor: pointer;
    width: 300px;
  }
  // ::v-deep .el-input__inner {
  //   margin-top: 2px;
  //   font-size: 20px;
  //   font-family: Adobe Heiti Std;
  //   font-weight: normal;
  //   color: #666 !important;
  //   height: 52px;
  //   border: none;
  //   width: 420px !important;
  //   padding-left: 0;
  //   background: rgba(0, 0, 0, 0) !important;
  //   border: none !important;
  // }
  .inputList .el-select .el-input .el-select__caret {
    font-size: 26px !important;
  }
  .inputList .el-input__icon {
    line-height: 51px !important;
  }
  .tijiao {
    width: 160px;
    height: 50px;
    background: #4486ff;
    border-radius: 4px;
    font-size: 29px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin-top: 29px;
  }
  .close {
    position: absolute;
    top: 183px;
    right: 455px;
    width: 36px;
    height: 36px;
    z-index: 1000000;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
